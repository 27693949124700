import React from 'react'
import { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import styles from './ProjectDetailHead.module.css'
import ProjectHeadLinks from '../ProjectHeadLinks/ProjectHeadLinks'
export default function ProjectDetailHead({projectID, needsDesc, showLinks}) {
    const [loaded, setLoaded] = useState(false);
    const data = useStaticQuery(
        graphql`
        query {
            results: allMarkdownRemark(filter: {fields: {isRoot: {eq: true}}}) {
              edges {
                node {
                  frontmatter {
                    links {
                      iOS
                      github
                      macOS
                    }
                    colour
                    colouredTitle
                    projectID
                    projectTitle
                  }
                  html
                }
              }
            }
          }
          
          
        `
      )
      const projectMD = data.results.edges.find(node => node.node.frontmatter.projectID === projectID).node
      const size = useWindowSize();

    let iOSDiv, macOSDiv, githubDiv, bottomDiv, links;
    if (projectMD.frontmatter.links.iOS && projectMD.frontmatter.links.iOS !== "") {
        iOSDiv =
            <a className = {styles.link} href={projectMD.frontmatter.links.iOS} target="_blank" rel="noopener noreferrer">
                <div className = {styles.linkWrapper}>
                    <img src = {require('./images/iPhone.svg')} className = {styles.linkImage} alt = "Button Logo"></img>
                    <h3 className = {styles.linkTitle}>iOS App Store</h3>
                </div>
            </a>
    }
    if (projectMD.frontmatter.links.macOS && projectMD.frontmatter.links.macOS !== "") {
        macOSDiv = 
            <a className = {styles.link} href={projectMD.frontmatter.links.macOS} target="_blank" rel="noopener noreferrer">
                <div className = {styles.linkWrapper}>
                    <img src = {require('./images/Window.svg')} className = {styles.linkImage} alt = "Button Logo"></img>
                    <h3 className = {styles.linkTitle}>Mac App Store</h3>
                </div>
            </a>
    }
    if (projectMD.frontmatter.links.github && projectMD.frontmatter.links.github !== "") {
        githubDiv = 
            <a className = {styles.link} href={projectMD.frontmatter.links.github} target="_blank" rel="noopener noreferrer">
                <div className = {styles.linkWrapper}>
                    <img src = {require('./images/Github.svg')} className = {styles.linkImage} alt = "Button Logo"></img>
                    <h3 className = {styles.linkTitle}>Github</h3>
                </div>
            </a>
    }
    if(needsDesc && size.width !== undefined) {
      try {
        let image = require('../../images/projects/' + projectMD.frontmatter.projectID + (size.width > 500 ? "Hero.svg" : "HeroMobile.svg"))
        bottomDiv = 
        <div className = {loaded ? styles.bottom : styles.hidden}>
            <img src = {require('../../images/projects/' + projectMD.frontmatter.projectID + (size.width > 500 ? "Hero.svg" : "HeroMobile.svg"))} className = {styles.showcase} onLoad={() => setLoaded(true)} alt = {projectMD.frontmatter.projectTitle + projectMD.frontmatter.colouredTitle + " Showcase"}></img>
            <div className = {styles.flexLinks}>
                {iOSDiv}
                {macOSDiv}
                {githubDiv}
            </div>
            <div className = {styles.projectDescription} dangerouslySetInnerHTML={{ __html: projectMD.html }} />
        </div>
      } catch(err) {
        bottomDiv = <div>
          <div className = {styles.projectDescription} dangerouslySetInnerHTML={{ __html: projectMD.html }} />
        </div>
      }

    }
    if (showLinks) {
      links = <ProjectHeadLinks projectID = {projectMD.frontmatter.projectID}></ProjectHeadLinks>
    }
    return (
        <div className = {styles.outerFlex}>
            <div className = {styles.logoFlex}>
                <div className = {styles.logoBack}>
                    <img src = {require('../../images/icons/' + projectMD.frontmatter.projectID +'.png')} className = {styles.image} alt = "Button Logo"></img>
                </div>
                <h1 className = {styles.title}>{projectMD.frontmatter.projectTitle}
                <span style = {!projectMD.frontmatter.colour ? {} : {color: projectMD.frontmatter.colour}}>
                {projectMD.frontmatter.colouredTitle}
                </span>
                </h1>

            </div>
            {links}

            {bottomDiv}
        </div>
            
    )
}


// Hook
function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      
      // Add event listener
      window.addEventListener("resize", handleResize);
      
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
  
    return windowSize;
  }