import React from 'react'
import { graphql } from "gatsby"
import ProjectsNavigation from '../ProjectsNavigation/ProjectsNavigation'
import styles from './ProjectDetail.module.css'
import ProjectDetailHead from './ProjectDetailHead'
import SEO from '../SEO/seo'
export default function ProjectDetail({data}) {
    const post = data.markdownRemark
    let bottomData;

    if(post.fields.isRoot) {
        bottomData = <div/>
    } else {
        bottomData = <div className = {styles.markdown} dangerouslySetInnerHTML={{ __html: post.html }} />
    }
    if(!post) { return null;}

    return (
        <ProjectsNavigation>
            <SEO title = {post.frontmatter.seoTitle} description = {post.frontmatter.seoDescription} imagePath = {"/share/" + post.frontmatter.projectID + ".jpg"}/>
            <div className = {styles.main}>
                <div className = {styles.flex}>
                    <ProjectDetailHead showLinks = {post.frontmatter.linkTitle !== null || post.fields.isRoot} projectID = {post.frontmatter.projectID} needsDesc = {post.fields.isRoot}></ProjectDetailHead>
                    <h2 className = {styles.title}>{post.frontmatter.title}</h2>
                    {bottomData} 
                </div>
            </div>
        </ProjectsNavigation>
    )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
          isRoot
      }
      frontmatter {
        title
        seoTitle
        seoDescription
        linkTitle
        projectID
        projectTitle
      }
    }
  }
`
