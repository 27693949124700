import React from 'react'
import { useStaticQuery, Link, graphql } from "gatsby"
import styles from './ProjectHeadLinks.module.css'
export default function ProjectHeadLinks({projectID}) {
    const data = useStaticQuery(
        graphql`
        query {
            results: allMarkdownRemark(filter: {fields: {isRoot: {eq: false}}}) {
              edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        linkTitle
                        projectID
                    }
                }
              }
            }
            otherResults: allMarkdownRemark(filter: {fields: {isRoot: {eq: true}}}) {
                edges {
                  node {
                      id
                      fields {
                          slug
                      }
                      frontmatter {
                          title
                          projectID
                      }
                  }
                }
              }
          }
          
          
        `
      )
      const projectMD = data.otherResults.edges.find(node => node.node.frontmatter.projectID === projectID).node
      const sublinks = data.results.edges.filter(node => node.node.frontmatter.projectID === projectID && node.node.frontmatter.linkTitle !== null)
      console.log(sublinks)
      if(sublinks.length === 0) {
          return null;
      }
      if (typeof window === `undefined`) {
        return null;
      }
      return <div className = {styles.outerFlex}>
          <Link key = {projectMD.id} to = {projectMD.fields.slug}>
          <div className = {styles.inline}>
            <h2 className = {styles.title}>Info</h2>
            <div className = {styles.box + (projectMD.fields.slug.split("/").filter(val => val !== "").pop() === window.location.pathname.split("/").filter(val => val !== "").pop() ? (" " + styles.shadow) : "")}/>
        </div>
          </Link>
          {sublinks.map(node => {
              return <Link key = {node.node.id} to = {node.node.fields.slug}>
                <div key = {node.node.id} className = {styles.inline}>
                    <h2 className = {styles.title}>{node.node.frontmatter.linkTitle}</h2>
                    <div className = {styles.box + (node.node.fields.slug.split("/").filter(val => val !== "").pop() === window.location.pathname.split("/").filter(val => val !== "").pop() ? (" " + styles.shadow) : "")}/>
                </div>
                  </Link>
          })}
      </div>
};